
.selector {
    width: 100%;
    height: 40px;
    padding: 10px;
    border: 2px solid black;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    background-color: white;
    font-weight: 600;
    font-size: 15px;
    
  }
  .selector:hover {
    outline: none;
    border-color: #F7CB46;
    background-color: #fff4d4;
  }
  
  .countryList {
    display: none; 
    flex-direction: column;
    max-height: 200px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px;
    position: absolute;
    z-index: 1000;
    background-color: white;
    top: 489px;
    display: none;
  }
  .visible {
    display: flex;
  }
  
  .countryItem {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .countryItem:hover {
    outline: none;
    border-color: #F7CB46;
    background-color: #fff4d4;
  }
  
  .selectedCountryItem {
    background-color: #F7CB46;
  }
  