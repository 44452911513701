.mainDivPrompt{
  height: 91vh; 
  width: 100%
}
  .stepBar{
    width: 80%;
    height: 20px;
    text-transform: none;
    border: 2px solid black;
    font-weight: 700;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 1);
    font-size: 20px;
    background-color: #FF7396;
    cursor: pointer;
    transition: all 0.3s ease;
    color:black;
    
    margin-top: 2%;
    background-image: linear-gradient(to right, #F7CB46 var(--gradient-percentage, 14.1%), white 10%);
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 50%; 
    transform: translateX(-50%);
    padding: 1% 0 1% 150px;
    display: flex; 
    justify-content: space-between; 
    align-items: center;
  }

  .circle {

    width: 30px;
    height: 30px; 
    font-size: 20px;
    border-radius: 50%;
    border: 2px solid black;
    display: flex;
    justify-content: center; 
    align-items: center; 
    font-family: 'Poppins', sans-serif; 
    color: black; 
}
.circle:nth-child(7) {
  margin-right:-1px;
}

.active {
  background-color: #F7CB46;
}
.divPagination {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  
}

.divGifto{
  width:45%;
  height:100%;
  display:flex;
  align-items:center; 
  justify-content:flex-end;
  z-index:15
}

.imgGifto{
  width:25%;
  margin-top:5%;
  z-index:15;
}

@media (max-width: 480px) {

  .circle {
    border:none;
    border-right: 2px solid black;
    border-radius: 0;
    padding-right: 8%;
    height: 25px; 

  }

  .stepBar {
    margin:0;
    height:25px;
    padding-left: 7%;
    width:100%;
    border-radius: 0;
  }
  .circle, .circle.active {
    font-size: 0;
  }
  .divGifto{
    width:50%;
    justify-content: center;
    align-items: center;
    
  }
  .imgGifto{
    width: 60%;
  }
}

