
.minusPlus{
    border: 1px solid black;
    border-radius: 0 15px 15px 0;
    padding:20px 30px 20px 30px;
    margin-right: 15px;
}  

.boxHistory{
    width: 100%;
    height: 75px;
    
    text-transform: none;
    border: 2px solid;
    font-weight: 700;
    border-radius: 15px;
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 1);
 
    background-color: white;
    cursor: pointer;
    transition: all 0.3s ease; 
}
.contentWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border-radius: 15px; 
    overflow: hidden;
    font-weight: 600;
    font-size: 1.2vw;
}
.divIdeaDelete{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.dateHistory{
    padding:10px;
    background-color: #F7CB46;
    width:40%;
    border-radius:15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.destHistory{
    padding:10px;
    margin:10px;
    background-color: white;
    width:60%;
    border-left: 3px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.giftHistory{
    padding:10px;
    margin:10px;
    background-color: white;
    width:90%;
    border-left: 3px solid black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.deleteHistory{
    background-color: #FF8000;
    width:8%;
    border-radius: 15px 0 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.divLineHistory{
    width:50%;
    display:flex;
    
}

@media (max-width: 480px) {
    .boxHistory{
        height: auto;
    
    }
    .contentWrapper{
        flex-direction: column;
    }
    .dateHistory{
        border-radius: 0;
        width:50%;
        font-size: 15px;
    }
    
    .divIdeaDelete{
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .giftHistory{
        border:none;
        font-size: 15px;
    }
    .deleteHistory{
        width:100%;
        border-radius: 0;

    }
    
    .divLineHistory{
        width:100%;
        border-bottom: 2px solid black;
          
    }
    .destHistory{
        margin:0;
        font-size: 15px;
        border-left: 2px solid black;

    }
    
}
  