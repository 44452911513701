.divFlexInfo{
    display: flex;
    align-items: center; 
    gap: 20px;
    margin-top:20px; 
}
.divPhone{
    width:100%;
}
.divFinalBtn{
    display:flex;
    align-items:flex-end;
}

@media (max-width: 480px) {
    .divFlexInfo{
        flex-direction: column;
    }
}