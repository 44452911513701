.mainDivPrompt{
    height: auto; 
    width: 100%
  }
.divGifto{
    width:100%;
    height:100%;
    display:flex;
    align-items:center; 
    justify-content:center;
    z-index:15
  }
  
  .imgGifto{
    width:15%;

    z-index:15;
  }
  
  @media (max-width: 480px) {
    .mainDivPrompt{
        height: auto; 
        width: 100%
      }
    .divGifto{
      justify-content: flex-start;
      
    }
    .imgGifto{
      width: 40%;
    }
  }
  