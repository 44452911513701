.logo{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.line1{
    padding:1% 0 1% 0;
    height:100%;
    width:13%;
    display:flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background: url('../../../public/assets/footerLine1.svg');
    background-repeat: no-repeat;
    filter: 
    drop-shadow(-8px 0 4.2px rgba(0, 0, 0, 0.25))
    drop-shadow(8px 0 4.2px rgba(0, 0, 0, 0.25));
}
.line1 img{
    width:30%;
}
.line1 hr{
    border:none;
    border-radius: 15px;
    background-color: black;
    width:15%;
    height:2px;
    margin:0;
}
.content{
    color:white;
    width:45%;
    display:flex;
    height:100%;
    justify-content: center;
    align-items: center;
    gap:15%
}

.divAbout, .divLegal{
    height:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:5%
}

.ulAbout li, .ulLegal li{
    text-decoration: none;
    list-style-type: none;
    font-size: 16px;
   
}
.ulAbout, .ulLegal{
    height:35%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    gap:5%;
    padding:0;
}

.line2{
    height:100%;
    width:15%;
    padding-left:1%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../../public/assets/footerLine2.svg');
    background-repeat: no-repeat;
    filter: drop-shadow(-8px 0 4.2px rgba(0, 0, 0, 0.25))

}

.line2menu {
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10%   
}


@media (max-width: 480px) {
    .logo{
        width: 80%;
        height: 15%;
        margin-top: 50px;
    }
    .line1 {
        background: url('../../../public/assets/footerLine1mobile.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width:100%;
        padding-top: 50px;
        height: 35%;

    }
    .line1 img{
        width:17%;
    }
    .line1 hr{
        transform: rotate(90deg);
    }
    .content{
        /* padding-top: 100px; */
        width: 100%;
        justify-content: flex-start;
        flex-direction: column;
        gap: 30vw;
        height: 40%;
        padding: 40px 0 50px 0;
    }
    .divAbout, .divLegal{
        height: 25%;
        justify-content: flex-start;
    }
    .ulAbout li, .divLegal li{
        font-size: 5vw;
        
    }
    .line2{
        background: url('../../../public/assets/footerLine2mobile.svg');
        background-repeat: no-repeat;
        width:100%;
        height: 30%;
        background-size: 100%;
        padding:0;
        padding-top: 50px;
    }
    .line2menu{
        flex-direction: row;
        gap: 5vw;
        height: 50%;
        align-items: flex-end;
        flex-wrap: wrap;
    }
    .divLineBtn{
        display: flex;
        justify-content: center;
        align-items: center;
        width:47%;
     
    }
    
}