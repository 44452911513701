.authContainer {
  display: flex;
  height: 91vh;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  
  
}

.authPaper {
  z-index: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;

}

.leftBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #FF7396;
  width: 50%;
  height: 100%;
}

.welcomeBack {
  width: 550px;
  height: 185px;
  font-weight: 700;
  text-align: left;
  font-size: 80px;
  
}

.connexionButton {
  width: 239px;
  height: 75px;
  
  text-transform: none;
  border: 2px solid;
  font-weight: 700;
  border-radius: 30px;
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 1);
  font-size: 24px;
  background-color: #f7cb46;
  cursor: pointer;
  transition: all 0.3s ease; 
}
.connexionButton:hover {
  background-color: transparent; 
}

.connexionButton:active {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
  transform: translateY(2px); 
}


.rightBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding-top: 50px;
  background-color: #f7cb46;
}

.inscriptionHeader {
  width: 226px;
  height: 49px;
  font-weight: 700;
  font-size: 40px;
  margin-top:0;
  margin-bottom:20px;
}

.socialButtons {
  display: flex;
  justify-content: space-evenly;
  gap: 2px;
  width: 352px;
  margin: 15px 0 20px 0;
}

.iconButton {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius:100%
}

.icon {
  color: black;
}

.emailText {
  margin: 1px 0;
  font-size: 17px;
}

.authForm {
  display: flex;
  align-items: center;
  padding: 0 200px;
  flex-direction: column;
  width: 100%;
}

.authTextField {
  background-color: white;
  
}

.inscriptionButton {
  width: 239px;
  height: 75px;
  margin-top: 42px;
  text-transform: none;
  border: 2px solid;
  font-weight: 700;
  border-radius: 30px;
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 1);
  font-size: 24px;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.inscriptionButton:hover {
  background-color: transparent; 
}

.inscriptionButton:active {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5); 
  transform: translateY(2px);
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

.hiddenButton {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.waves{
  
  position: absolute;
  overflow: hidden;
  left: 48%;
  width: 30%;
  height: 100%;
  background-image: url('../../public/assets/login.svg');
  background-repeat: no-repeat;
  background-size: cover;

  
}

@media (max-width: 480px) {
  .authContainer {
    height: auto; 
  }
  .authPaper {
    flex-direction: column;
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  .emailText {
    margin: 0 !important;
    font-size: 4vw !important;
  }
  .authForm{
    padding-top:0 !important;

  }
  .inscriptionButton{
    height: 10vw;
    font-size: 5vw;
    margin-top: 20px !important;
    margin-bottom: 20px !important;

  }
  .connexionButton{
    height: 10vw;
    font-size: 5vw;
    margin-top: 0px !important;
    margin-bottom: 50px !important;

  }
  .rightBox, .leftBox{
    width:100%;
    text-align: center;
  }
  .waves {
    top: 23%;
    transform: rotate(90deg);
    
  }

  .authForm{
    padding: 25px 50px;
  }
  .welcomeBack{
    width: 100%;
    height:auto;
    text-align: center !important;
    font-size:6vw;
    margin: 80px 0 30px 0 !important;
    margin:0;
    color:white;
    line-height: 50px !important;
  }
  .leftBox p{
    display: none;
  }
 
  .leftBox {
    order: 2 !important;
  }

  .rightBox {
    height:80% !important;

    order: 1 !important;
  }

  .waves {
    order: 3 !important;
  }
}
