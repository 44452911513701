.createPostButton {
    width: 275px;
    height: 49px;
    
    text-transform: none;
    border: 2px solid;
    font-weight: 600;
    border-radius: 25px;
    /* box-shadow: 0px 4px 0px rgba(0, 0, 0, 1); */
    font-size: 20px;
    background-color: white;
    cursor: pointer;
    transition: all 0.3s ease; 
  }
  .createPostButton:hover {
    background-color:#F7CB46; 
  }
  
  .createPostButton:active {
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
    transform: translateY(2px); 
  }