@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');

/* Custom scrollbar */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: var(--scrollbar-track-color, #ffffff);

}

::-webkit-scrollbar-thumb {
  border-top-left-radius: var(--scrollbar-thumb-border-radius-top, 8px);
  border-top-right-radius: var(--scrollbar-thumb-border-radius-top, 8px);
  border-bottom-left-radius: var(--scrollbar-thumb-border-radius-bottom, 8px);
  border-bottom-right-radius: var(--scrollbar-thumb-border-radius-bottom, 8px);
  background-color: var(--scrollbar-thumb-color, #FF7396);
}
