.profilButton {
    width: auto;
    padding: 0 20px 0 20px;
    height: 50px;
    margin: 0 0 0 10px;
    text-transform: none;
    border: 2px solid;
    font-weight: 700;
    border-radius: 30px;
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 1);
    font-size: 15px;
    background-color: #F7CB46;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .profilButton:hover {
    background-color: transparent; 
    color:white;
  }
  
  .profilButton:active {
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5); 
    transform: translateY(2px);
  }

  .svgSeparate{
    background-color: #F7CB46;
    height: 100%;
  }
  .headerIcons{
    color: white;
    width:100% !important;
    height:60%;
  }